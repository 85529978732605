@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.k-list
  ul
    list-style-type: none
    padding: 0

    li
      border-bottom: 1px solid gray

.k-loading-line
  margin: 0
